import { AllHTMLAttributes, ReactNode, useMemo, useRef } from 'react'
import { useWidgetQuery } from '../hooks'
import { Widget } from './Widget'
import { MetricsCellConfig } from 'core/types'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { CommonTooltip } from './CommonTooltip'
import classNames from 'classnames'
import { ChartValueType, PIE_CHART_COLOR_PALETTES_MAP, PieChartColorPaletteName } from '../models'

type PieWidgetChartProps = {
  header: ReactNode
  config: MetricsCellConfig
  palette?: PieChartColorPaletteName
  valueType?: ChartValueType
} & AllHTMLAttributes<HTMLDivElement>
export function WidePieChartWidget({
  header,
  config,
  className,
  valueType = 'MONEY',
  palette = 'RANDOM',
}: Readonly<PieWidgetChartProps>) {
  const { data, loading } = useWidgetQuery(useRef(config).current)
  const isDataEmpty = data?.getBarDiagram.length === 0
  const PIE_COLORS = useMemo(() => PIE_CHART_COLOR_PALETTES_MAP[palette], [palette])

  return (
    <Widget
      style={{ height: '200px' }}
      header={header}
      className={classNames('WidePieChartWidget', className)}
      isLoading={loading}
      noData={isDataEmpty}
    >
      {data && (
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              data={data.getBarDiagram}
              dataKey="value"
              fill="#8884d8"
              cx="50%"
              cy="890%"
              outerRadius={1200}
              innerRadius={1180}
              startAngle={70}
              endAngle={110}
              label={({ classifier }) => `${classifier}`}
              legendType="cross"
            >
              {data.getBarDiagram.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={PIE_COLORS[index % PIE_COLORS.length] ?? '#f0f'}
                  stroke="none"
                />
              ))}
            </Pie>
            <Tooltip content={<CommonTooltip valueType={valueType} />} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </Widget>
  )
}
