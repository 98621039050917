import { JournalInfo, TradeItem } from 'core/types'
import { ReactNode, useContext, useMemo } from 'react'
import Table from 'shared/Table'
import { TradesTableSectionContext } from '../../TradesTableSectionContext'
import { useSelectedJournal } from '../../../Filters/useSelectedJournal'
import { useUserInfo } from 'core/api/common'
import { MenuCell } from './cells/MenuCell'
import { AllowedTradeKeys } from '../../TradesTableSectionContext/models'
import { TRADES_TABLE_CELLS_RENDER_MAP } from './cells/cells-render-map'
import { FilterContext } from 'core/contexts/FilterContext'

type TradesTableDynamicRowProps = { item: TradeItem; journalsIdMap: Record<string, JournalInfo> }
export function TradesTableDynamicRow({
  item,
  journalsIdMap,
}: Readonly<TradesTableDynamicRowProps>) {
  const { filters } = useContext(FilterContext)
  const { openTradeInfoModal, columns } = useContext(TradesTableSectionContext)

  const selectedJournal = useSelectedJournal()
  const userInfo = useUserInfo()

  const showTradesActionColumn =
    selectedJournal === null || userInfo.data?.userEmail === selectedJournal?.owner.userEmail

  const finalCells: ReactNode = useMemo(() => {
    return columns.map((tableHeader, idx) => {
      const key = tableHeader.sortableKey as AllowedTradeKeys
      const func = TRADES_TABLE_CELLS_RENDER_MAP[key]
      return func(item[key] as never, idx + 'key')
    })
  }, [columns, item])

  return (
    <Table.Row onClick={() => openTradeInfoModal(item)}>
      {filters.journal === null && (
        <Table.Cell className="no-wrap">
          {journalsIdMap[item.journalId]?.journalName ?? '-'}
        </Table.Cell>
      )}

      {finalCells}

      <NoteCell noteItem={item.tradeNote} />
      {showTradesActionColumn && <MenuCell item={item} />}
    </Table.Row>
  )
}

type NoteCellProps = { noteItem: TradeItem['tradeNote'] }
function NoteCell({ noteItem }: Readonly<NoteCellProps>) {
  return (
    <Table.Cell className="NoteCell">
      {noteItem === null || noteItem.noteText === '' ? (
        <div className="text empty"> - </div>
      ) : (
        <abbr title={noteItem.noteText} className="text">
          {noteItem.noteText}
        </abbr>
      )}
    </Table.Cell>
  )
}
