import { createContext } from 'react'
import { FilterContextData } from './models'

export const FilterContext = createContext<FilterContextData>({
  filters: {
    dateFrom: new Date(0),
    dateTo: new Date(0),
    symbols: [],
    journal: null,
  },
  setFilters: () => {},
})
