import './TradeInfoModal.scss'
import { FlatCard } from 'shared/FlatCard/FlatCard'
import ModalHeader from 'shared/ModalHeader/ModalHeader'
import { TradeInfoGrid } from './components/TradeInfoGrid'
import { TradeInfoNoteSection } from './components/TradeInfoNoteSection/TradeInfoNoteSection'
import { TradeInfoModalContextProvider } from './TradeInfoModalContext'

type TradeInfoDialogProps = {
  onClose: () => void
}
function TradeInfoModal({ onClose }: Readonly<TradeInfoDialogProps>) {
  return (
    <TradeInfoModalContextProvider onClose={onClose}>
      <FlatCard className="TradeInfoDialog">
        <ModalHeader label="Trade info" onClose={() => onClose()} />
        <div className="content">
          <TradeInfoGrid />
          <TradeInfoNoteSection />
        </div>
      </FlatCard>
    </TradeInfoModalContextProvider>
  )
}

export default TradeInfoModal
