import './WidgetEmptyState.scss'

export function WidgetEmptyState() {
  return (
    <div className="WidgetEmptyState chart">
      <div className="WidgetEmptyState__title">No trades</div>
      <div className="WidgetEmptyState__description">
        It looks like there are no results that match your search criteria. Please, try another one
      </div>
    </div>
  )
}
