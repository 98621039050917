import Color from 'color'

export type ChartValueType = 'DEFAULT' | 'MONEY' | 'TRADES' | 'DURATION_MS'

export const PIE_CHART_COLOR_PALETTES_NAMES = [
  'POLARIZED',
  'POLARIZED_INVERTED',
  'NEUTRAL',
  'RANDOM',
] as const
export type PieChartColorPaletteName = (typeof PIE_CHART_COLOR_PALETTES_NAMES)[number]
export const PIE_CHART_COLOR_PALETTES_MAP: Record<PieChartColorPaletteName, string[]> = {
  POLARIZED: ['#f53b54', '#1fdb51'],
  POLARIZED_INVERTED: ['#1fdb51', '#f53b54'],
  NEUTRAL: ['#FF9D3D', '#791be3'],
  RANDOM: Array(20)
    .fill(0)
    .map((_, index) => new Color('#33f066').rotate(110 * index).hex()),
}

export const CHART_BAR_COLORS = {
  positive: '#1fdb51',
  negative: '#f53b54',
  neutral: '#8884d8',
  base: '#aaa',
}
