import {
  AllHTMLAttributes,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { TradeInfoModalContext } from '../../TradeInfoModalContext'
import { MediaItem, TradeItem } from 'core/types'
import { Modal, useModal } from 'shared/Modal'
import { Button } from 'shared/Button'
import { useMediaForTrade } from 'core/api/trades'
import Icon from 'shared/Icon'
import SkeletonBlock from 'shared/SkeletonBlock'
import classNames from 'classnames'

type TradeInfoDialogNotePreviewProps = AllHTMLAttributes<HTMLDivElement>
export function TradeInfoNotePreview(props: Readonly<TradeInfoDialogNotePreviewProps>) {
  const { tradeItem } = useContext(TradeInfoModalContext)
  if (!tradeItem.tradeNote) return <div>'Nothing to show'</div>
  return <Preview note={tradeItem.tradeNote} tradeId={tradeItem.id} />
}

type PreviewProps = { note: NonNullable<TradeItem['tradeNote']>; tradeId: string }
function Preview({ note, tradeId }: PreviewProps) {
  const mediaResponse = useMediaForTrade(tradeId)

  const showSkeleton: boolean = note.mediaCount > 0 && mediaResponse.isLoading
  const listContent: ReactNode = useMemo(() => {
    if (!mediaResponse.data) return null
    return (
      <>
        {mediaResponse.data.result.map((item) => (
          <ImageItem mediaItem={item} key={item.key} />
        ))}
      </>
    )
  }, [mediaResponse.data])

  return (
    <div className="Preview">
      <div className="text">
        {note.noteText !== '' ? (
          note.noteText
        ) : (
          <div className="empty color-secondary-l3">No text</div>
        )}
      </div>
      <div className="images">
        {showSkeleton && <SkeletonBlock className="media-skeleton" />}
        {listContent}
      </div>
    </div>
  )
}

function useTrackImgLoading(ref: RefObject<HTMLImageElement>) {
  const [isLoading, setIsLoading] = useState(false)

  const handleLoad = useCallback(() => setIsLoading(false), [])
  const handleError = useCallback(() => setIsLoading(false), [])

  useEffect(() => {
    const img = ref.current
    if (!img) return
    if (img.complete) return setIsLoading(false)

    setIsLoading(true)
    img.addEventListener('load', handleLoad)
    img.addEventListener('error', handleError)

    return () => {
      if (!img) return
      img.removeEventListener('load', handleLoad)
      img.removeEventListener('error', handleError)
    }
  }, [ref, handleLoad, handleError])

  return isLoading
}
type ImageItemProps = { mediaItem: MediaItem }
function ImageItem({ mediaItem }: ImageItemProps) {
  const previewModal = useModal()
  const imgRef = useRef<HTMLImageElement>(null)
  const isThumbnailLoading = useTrackImgLoading(imgRef)

  return (
    <>
      <button
        className={classNames('ImageItem', isThumbnailLoading && 'loading')}
        onClick={() => previewModal.open()}
      >
        <img
          ref={imgRef}
          src={mediaItem.thumbnailSignedUrl}
          alt="thumbnail"
          className="thumbnail"
        />
        <SkeletonBlock className="thumbnail-skeleton" />
        <div className="hover">
          <Icon name="ZoomIn" width="24px" height="24px" />
        </div>
      </button>
      <Modal controller={previewModal}>
        <div className="ImageItemViewModal">
          <img src={mediaItem.thumbnailSignedUrl} alt="thumbnail" className="thumbnail" />
          <img src={mediaItem.signedUrl} className="full" alt="Full" />
          <Button
            className="close-button"
            square
            size="large"
            appearance="menu-item"
            onClick={() => previewModal.close()}
          >
            <Icon name="Cross" width="24px" height="24px" />
          </Button>
        </div>
      </Modal>
    </>
  )
}
