import { AllHTMLAttributes, ReactNode, useCallback, useMemo, useRef } from 'react'
import { useWidgetQuery, useYAxisWidth } from '../hooks'
import { Widget } from './Widget'
import { BarDiagramItem, MetricsCellConfig } from 'core/types'
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CommonTooltip } from './CommonTooltip'
import classNames from 'classnames'
import { CHART_BAR_COLORS, ChartValueType } from '../models'
import { durationToHumanReadable, formatMoney } from 'core/utils'
import Color from 'color'

function useGetBarColor(data: BarDiagramItem[], polarized: boolean) {
  const maxAbs = useMemo(() => {
    if (data.length === 0) return 0
    return Math.max(...data.map((item) => Math.abs(item.value)))
  }, [data])

  const getAccentColor = useCallback(
    (value: number) => {
      if (!polarized) return CHART_BAR_COLORS.neutral
      return value > 0 ? CHART_BAR_COLORS.positive : CHART_BAR_COLORS.negative
    },
    [polarized]
  )

  const getBarColor = useCallback(
    (value: number) => {
      const accentColor = getAccentColor(value)
      const weight = Math.abs(value) / maxAbs + (polarized ? 0.3 : 0)
      return new Color(CHART_BAR_COLORS.base).mix(new Color(accentColor), weight).hex()
    },
    [getAccentColor, maxAbs, polarized]
  )

  return { getBarColor }
}

type BarWidgetChartProps = {
  header: ReactNode
  config: MetricsCellConfig
  valueType?: ChartValueType
  polarized?: boolean
} & AllHTMLAttributes<HTMLDivElement>
export function BarChartWidget({
  header,
  config,
  className,
  valueType = 'DEFAULT',
  polarized = false,
  ...rest
}: Readonly<BarWidgetChartProps>) {
  const { data, loading } = useWidgetQuery(useRef(config).current)
  const isDataEmpty = data?.getBarDiagram.length === 0
  const { getBarColor } = useGetBarColor(data?.getBarDiagram || [], polarized)

  const yAxisWidth =
    useYAxisWidth(
      data?.getBarDiagram || [],
      useCallback((item) => item.value, [])
    ) - (valueType === 'MONEY' ? 0 : 35)

  const formatYTickMap: Record<ChartValueType, (value: number) => string> = useRef({
    DEFAULT: (value: number) => value.toString(),
    MONEY: (value: number) => formatMoney(value),
    TRADES: (value: number) => value.toString(),
    DURATION_MS: (value: number) => durationToHumanReadable(value),
  }).current

  return (
    <Widget
      header={header}
      isLoading={loading}
      noData={isDataEmpty}
      className={classNames('BarWidgetChart', className, 'bruh')}
      {...rest}
    >
      {data && (
        <ResponsiveContainer width="100%" height={275}>
          <BarChart
            data={data.getBarDiagram}
            margin={{
              top: 10,
              right: 10,
              left: 10,
              bottom: 0,
            }}
          >
            <Bar dataKey="value" fill="#8884d8">
              {data.getBarDiagram.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getBarColor(entry.value)} />
              ))}
            </Bar>
            <Tooltip cursor={{ fill: '#fff2' }} content={<CommonTooltip valueType={valueType} />} />
            <YAxis
              dataKey="value"
              type="number"
              width={yAxisWidth}
              tickFormatter={formatYTickMap[valueType]}
              style={{ fontSize: '12px', fill: '#9EACB2' }}
              stroke="#39525B"
              orientation="right"
            />
            <XAxis
              dataKey="classifier"
              type="category"
              style={{ fontSize: '12px', fill: '#9EACB2' }}
              stroke="#39525B"
            />
            <ReferenceLine y={0} stroke="#39525B" />
            {/* <CartesianGrid strokeDasharray="1" stroke="#444" /> */}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Widget>
  )
}
