import { ReactNode, useMemo, useRef } from 'react'
import { useWidgetQuery } from '../hooks'
import { Widget } from './Widget'
import { MetricsCellConfig } from 'core/types'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { CommonTooltip } from './CommonTooltip'
import { ChartValueType, PIE_CHART_COLOR_PALETTES_MAP, PieChartColorPaletteName } from '../models'

type PieWidgetChartProps = {
  header: ReactNode
  config: MetricsCellConfig
  valueType?: ChartValueType
  palette?: PieChartColorPaletteName
}
export function PieChartWidget({
  header,
  config,
  valueType = 'DEFAULT',
  palette = 'RANDOM',
}: Readonly<PieWidgetChartProps>) {
  const { data, loading } = useWidgetQuery(useRef(config).current)
  const isDataEmpty = data?.getBarDiagram.length === 0
  const PIE_COLORS = useMemo(() => PIE_CHART_COLOR_PALETTES_MAP[palette], [palette])

  return (
    <Widget header={header} isLoading={loading} noData={isDataEmpty}>
      {data && (
        <ResponsiveContainer width="100%" height={275}>
          <PieChart>
            <Pie
              data={data.getBarDiagram}
              dataKey="value"
              fill="#8884d8"
              cx="50%"
              cy="50%"
              outerRadius={110}
              innerRadius={70}
              label={({ classifier }) => `${classifier}`}
              legendType="cross"
              paddingAngle={1}
            >
              {data.getBarDiagram.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={PIE_COLORS[index % PIE_COLORS.length] ?? '#f0f'}
                  stroke="none"
                />
              ))}
            </Pie>
            <Tooltip content={<CommonTooltip valueType={valueType} />} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </Widget>
  )
}
