import { blankTradeItem, TradeItem } from 'core/types'
import { createContext, useContext, useMemo, useState } from 'react'
import { TradesTableSectionContext } from '../../TradesTableSectionContext'

export type TradeInfoModalContextData = {
  tradeItem: TradeItem
  editMode: boolean
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}
export const TradeInfoModalContext = createContext<TradeInfoModalContextData>({
  tradeItem: blankTradeItem,
  editMode: false,
  setEditMode: () => {},
  onClose: () => {},
})
type TradeInfoModalContextProviderProps = { children: React.ReactNode; onClose: () => void }
export function TradeInfoModalContextProvider({
  children,
  onClose,
}: TradeInfoModalContextProviderProps) {
  const { selectedTrade: tradeItem } = useContext(TradesTableSectionContext)
  if (tradeItem === null) throw new Error('Trade item is not selected. This should not happen.')
  const [editMode, setEditMode] = useState(false)

  const value = useMemo(
    () =>
      ({
        tradeItem,
        editMode,
        setEditMode,
        onClose,
      } satisfies TradeInfoModalContextData),
    [editMode, onClose, tradeItem]
  )
  return <TradeInfoModalContext.Provider value={value}>{children}</TradeInfoModalContext.Provider>
}
