import { PagingParams, SortingParams, usePnl, useStatistics, useTrades } from 'core/api/dashboard'
import { LS_DASHBOARD_SORT_PARAMS, TABLE_PAGE_SIZE } from 'core/constants'
import { PnlItem, SWRResp, TradeItem, TradesResponse, TradingStatistics } from 'core/types'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { INITIAL_SWR_DATA } from 'core/utils'
import { FilterContext } from 'core/contexts/FilterContext'

type DashboardApiContextData = {
  pnl: SWRResp<{ result: { dataPoints: PnlItem[] } }>
  statistics: SWRResp<{ result: TradingStatistics }>
  trades: SWRResp<TradesResponse>
  mutateAll: () => Promise<void>
  paginationPage: number
  sortParams: SortingParams<TradeItem>
  setPaginationPage: React.Dispatch<React.SetStateAction<number>>
  setSortParams: React.Dispatch<React.SetStateAction<SortingParams<TradeItem>>>
}

export const DashboardApiContext = createContext<DashboardApiContextData>({
  pnl: INITIAL_SWR_DATA,
  statistics: INITIAL_SWR_DATA,
  trades: INITIAL_SWR_DATA,
  mutateAll: () => {
    throw new Error('Not implemented')
  },
  paginationPage: 0,
  sortParams: { order: 'desc', sort: 'timeEntry' },
  setPaginationPage: () => void 0,
  setSortParams: () => void 0,
})

export const useDashboardApi = () => useContext(DashboardApiContext)

const DASHBOARD_SORTING_DEFAULTS = { order: 'desc', sort: 'timeEntry' }

function useDashboardPagingSortingParams() {
  const [paginationPage, setPaginationPage] = useState<number>(0)
  const [sortParams, setSortParams] = useState<SortingParams<TradeItem>>(() => {
    const savedSortParams = localStorage.getItem(LS_DASHBOARD_SORT_PARAMS)
    return savedSortParams ? JSON.parse(savedSortParams) : DASHBOARD_SORTING_DEFAULTS
  })

  useEffect(() => {
    localStorage.setItem(LS_DASHBOARD_SORT_PARAMS, JSON.stringify(sortParams))
  }, [sortParams])

  const pagingSortingParams = useMemo(
    () =>
      ({
        page: paginationPage,
        size: TABLE_PAGE_SIZE,
        ...sortParams,
      } satisfies PagingParams & SortingParams<TradeItem>),
    [paginationPage, sortParams]
  )

  return { pagingSortingParams, paginationPage, setPaginationPage, setSortParams, sortParams }
}

type DashboardApiContextProviderProps = { children: React.ReactNode }
export function DashboardApiContextProvider({
  children,
}: Readonly<DashboardApiContextProviderProps>) {
  const { filters } = useContext(FilterContext)

  const { pagingSortingParams, setPaginationPage, setSortParams, sortParams, paginationPage } =
    useDashboardPagingSortingParams()

  const pnl = usePnl(filters)
  const statistics = useStatistics(filters)
  const trades = useTrades(filters, pagingSortingParams)

  const providerValue: DashboardApiContextData = useMemo(
    () =>
      ({
        pnl,
        statistics,
        trades,
        paginationPage,
        sortParams,
        setPaginationPage,
        setSortParams,
        mutateAll: async () => {
          console.log('mutateAll start...')
          await Promise.all([pnl.mutate(), statistics.mutate(), trades.mutate()])
          console.log('mutated!')
        },
      } satisfies DashboardApiContextData),
    [pnl, statistics, trades, paginationPage, sortParams, setPaginationPage, setSortParams]
  )

  return (
    <DashboardApiContext.Provider value={providerValue}>{children}</DashboardApiContext.Provider>
  )
}
