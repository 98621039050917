export type TradingStatistics = {
  biggestLoss: number
  biggestWin: number
  losses: number
  totalLossesSize: number
  averageLossSize: number
  wins: number
  totalWinsSize: number
  averageWinSize: number
  winLossRatio: number | 'NaN'
  totalPnl: number
}

export type TradeItem = {
  id: string
  accountId: string
  priceEntry: number
  priceExit: number
  fills: number
  isBuy: boolean
  isLocked: boolean
  profit: number
  instrumentAlias: string
  tradeNote: { id: string; noteText: string; mediaCount: number } | null
  timeEntry: string | null
  timeExit: string | null
  journalId: string
  mae: number
  mfe: number
  postMae: number | null
  postMfe: number | null
  maxSize: number
  commission: number | null
}
export const blankTradeItem: TradeItem = {
  id: '',
  accountId: '',
  priceEntry: 0,
  priceExit: 0,
  fills: 0,
  isBuy: false,
  isLocked: false,
  profit: 0,
  instrumentAlias: '',
  tradeNote: null,
  timeEntry: null,
  timeExit: null,
  journalId: '',
  mae: 0,
  mfe: 0,
  postMae: null,
  postMfe: null,
  maxSize: 0,
  commission: null,
}

export type PnlItem = {
  time: string
  value: number
}

export type JournalInfo = {
  id: string
  journalCurrency: string
  journalDescription: string
  journalName: string
  journalType: string
  namespaceCode?: string | null
  nickname?: string | null
  userId?: string | null
  updateDate: string | null
  typeEditable: boolean
  owner: Contact
  sharedWith: Contact[]
}

export type JournalDetailedInfo = {
  id: string
  creationDate: string | null
  journalName: string
  maxDrawdown: number
  totalPnl: number
  totalTrades: number
  journalType: string
  typeEditable: boolean
  owner: Contact
  sharedWith: Contact[]
}

export type TradesResponse = {
  result: TradeItem[]
  pageInfo: { totalPages: number; totalElements: number }
}

export type AliasesGetResponse = {
  result: string[]
}

export type JournalType = {
  typeName: string
  canBeCreatedByUser: boolean
}

export type Contact = {
  userEmail: string
}

export const METRICS_PARAMETERS = [
  'profit',
  'maxSize',
  'mae',
  'mfe',
  'timeInTrade',
  'riskRewardRatio',
] as const
export type MetricsParameter = (typeof METRICS_PARAMETERS)[number]
export const METRICS_AGGREGATIONS = [
  'average',
  'total',
  'max',
  'min',
  'deviation',
  'relative_deviation',
  'count',
] as const
export type MetricsAggregation = (typeof METRICS_AGGREGATIONS)[number]
export const METRICS_CLASSIFICATIONS = [
  'instrumentAlias',
  'journal',
  'winLoss',
  'longShort',
  'dayOfWeek',
  'timeOfDay',
  'profit',
  'maxSize',
  'mae',
  'mfe',
  'timeInTrade',
  'riskRewardRatio',
] as const
export type MetricsClassification = (typeof METRICS_CLASSIFICATIONS)[number]

export type MetricsCellConfig = {
  parameter: MetricsParameter
  aggregation: MetricsAggregation
  classification: MetricsClassification
}

export type BarDiagramItem = { classifier: string; value: number }
export type BarDiagramResponse = { getBarDiagram: BarDiagramItem[] }

export type MediaItem = {
  key: string
  signedUrl: string
  thumbnailSignedUrl: string
}
