import classNames from 'classnames'
import './Card.scss'
import { AllHTMLAttributes, ReactNode } from 'react'

type CardProps = { children?: ReactNode; paddings?: boolean } & AllHTMLAttributes<HTMLDivElement>
export function Card({ children, className, paddings = true, ...rest }: Readonly<CardProps>) {
  return (
    <div className={classNames('Card', className || '', paddings && 'with-paddings')} {...rest}>
      {children}
    </div>
  )
}
