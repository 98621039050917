import classNames from 'classnames'
import { AllHTMLAttributes, ReactNode } from 'react'
import Card from 'shared/Card'
import SkeletonBlock from 'shared/SkeletonBlock'
import WidgetEmptyState from 'shared/WidgetEmptyState'

type WidgetProps = {
  children: ReactNode
  header: ReactNode
  isLoading?: boolean
  noData?: boolean
} & AllHTMLAttributes<HTMLDivElement>
export function Widget({
  children,
  header,
  isLoading,
  noData,
  className,
  ...rest
}: Readonly<WidgetProps>) {
  if (isLoading)
    return (
      <Card className={classNames('Widget', className)} {...rest}>
        <SkeletonBlock />
      </Card>
    )

  return (
    <Card className={classNames('Widget', className)} {...rest}>
      <div className="header">{header}</div>
      {noData ? <WidgetEmptyState /> : <div className="chart">{children}</div>}
    </Card>
  )
}
