import './WidgetsSection.scss'
import { BarChartWidget } from './components/BarChartWidget'
import { PieChartWidget } from './components/PieChartWidget'
import { WidePieChartWidget } from './components/WidePieChartWidget'

export function WidgetsSection() {
  return (
    <div className="WidgetsSection">
      <BarChartWidget
        header="Total profit by time of the day"
        config={{
          aggregation: 'total',
          classification: 'timeOfDay',
          parameter: 'profit',
        }}
        polarized={true}
        valueType="MONEY"
      />
      <PieChartWidget
        header="Long/short ratio"
        config={{
          aggregation: 'count',
          classification: 'longShort',
          parameter: 'profit',
        }}
        palette="NEUTRAL"
        valueType="TRADES"
      />
      <PieChartWidget
        header="Win/loss ratio"
        config={{
          aggregation: 'count',
          classification: 'winLoss',
          parameter: 'profit',
        }}
        palette="POLARIZED"
        valueType="TRADES"
      />

      <BarChartWidget
        header="Total profit by instrument"
        config={{
          aggregation: 'total',
          classification: 'instrumentAlias',
          parameter: 'profit',
        }}
        className="span2 move-forward-mobile"
        polarized={true}
        valueType="MONEY"
      />
      <BarChartWidget
        header="Total profit by day of the week"
        config={{
          aggregation: 'total',
          classification: 'dayOfWeek',
          parameter: 'profit',
        }}
        valueType="MONEY"
        polarized={true}
      />

      <BarChartWidget
        header="Total profit by time in trade"
        config={{
          aggregation: 'total',
          classification: 'timeInTrade',
          parameter: 'profit',
        }}
        polarized={true}
        valueType="MONEY"
      />
      <BarChartWidget
        header="Trades count by day of the week"
        config={{
          aggregation: 'count',
          classification: 'dayOfWeek',
          parameter: 'profit',
        }}
        valueType="TRADES"
      />
      <PieChartWidget
        header="Total time in trade by long/short"
        config={{
          aggregation: 'total',
          classification: 'longShort',
          parameter: 'timeInTrade',
        }}
        palette="NEUTRAL"
        valueType="DURATION_MS"
      />

      <BarChartWidget
        header="Average profit by time in trade"
        config={{
          aggregation: 'average',
          classification: 'timeInTrade',
          parameter: 'profit',
        }}
        polarized={true}
        valueType="MONEY"
      />
      <BarChartWidget
        header="Trades count by time of the day"
        config={{
          aggregation: 'count',
          classification: 'timeOfDay',
          parameter: 'profit',
        }}
        valueType="TRADES"
      />
      <BarChartWidget
        header="Total time in trade by day of the week"
        config={{
          aggregation: 'total',
          classification: 'dayOfWeek',
          parameter: 'timeInTrade',
        }}
        valueType="DURATION_MS"
      />

      <WidePieChartWidget
        header="Total time in trade by instrument"
        config={{
          aggregation: 'total',
          classification: 'instrumentAlias',
          parameter: 'timeInTrade',
        }}
        className="span3"
        valueType="DURATION_MS"
      />
    </div>
  )
}
