import { useUserInfo } from 'core/api/common'
import { useJournalsAll } from 'core/hooks/useJournalsAll'
import { TradeItem } from 'core/types'
import { durationToHumanReadable } from 'core/utils'
import { useMemo } from 'react'

export function useIsNoteEditable(tradeItem: TradeItem | null) {
  const userInfo = useUserInfo()
  const journalsAll = useJournalsAll()
  const notesJournal = useMemo(() => {
    if (!journalsAll.data) return null
    return journalsAll.data.find((j) => j.id === tradeItem?.journalId) ?? null
  }, [journalsAll.data, tradeItem])
  const isNoteFormActive = useMemo(() => {
    if (!userInfo.data || !notesJournal) return false
    return userInfo.data.userEmail === notesJournal.owner.userEmail
  }, [userInfo.data, notesJournal])
  return isNoteFormActive
}

export function useTableInfoGridData(selectedTrade: TradeItem | null) {
  return useMemo(() => {
    const dateEntry = new Date(selectedTrade?.timeEntry ?? '')
    const dateExit = new Date(selectedTrade?.timeExit ?? '')
    const durationText =
      selectedTrade?.timeEntry && selectedTrade?.timeExit
        ? durationToHumanReadable(Math.abs(dateExit.getTime() - dateEntry.getTime()))
        : 'N/A'

    return {
      dateEntry,
      dateExit,
      durationText,
    }
  }, [selectedTrade])
}
