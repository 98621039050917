import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { API_URL, LS_ACCESS_TOKEN } from 'core/constants'

const httpLink = new HttpLink({ uri: API_URL + '/api/dashboard/widgets/graphql' })
const authMiddlaWare = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(LS_ACCESS_TOKEN)
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
  return forward(operation)
})

export const graphQlClient = new ApolloClient({
  link: authMiddlaWare.concat(httpLink),
  cache: new InMemoryCache(),
})

// const token = localStorage.getItem(LS_ACCESS_TOKEN)
// export const graphQlClient = new ApolloClient({
//   uri: API_URL + '/api/dashboard/widgets/graphql',
//   cache: new InMemoryCache(),
//   headers: {
//     ...(token !== null && { Authorization: `Bearer ${token}` }),
//   },
// })
