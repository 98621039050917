import { AllHTMLAttributes, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import './Filters.scss'
import { DateInput } from 'shared/DateInput/DateInput'

import imgPlus from 'assets/icons/plus.svg'
import imgSettings from 'assets/icons/settings.svg'
import SimpleDropdown from 'shared/Dropdown/SimpleDropdown/SimpleDropdown'
import { useNavigate } from 'react-router-dom'
import { AliasesGetResponse, JournalInfo } from 'core/types'
import MultiselectDropdown from 'shared/Dropdown/MultiselectDropdown/MultiselectDropdown'
import { useApi } from 'core/hooks/api/useApi'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'
import classNames from 'classnames'
import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import { Button } from 'shared/Button'
import { IconsSrc } from 'core/assets'
import { useJournals, useJournalsSharedWithMe } from 'core/api/journals'
import { useJournalsAll } from 'core/hooks/useJournalsAll'
import { useUserInfo } from 'core/api/common'
import { useScrolling, useTooltipText } from './hooks'
import { FilterContext } from 'core/contexts/FilterContext'

type FiltersProps = AllHTMLAttributes<HTMLDivElement>
/** Section with date & time filters */
export function Filters({ className, ...props }: Readonly<FiltersProps>) {
  const { filters, setFilters } = useContext(FilterContext)
  const { openAddDialog, openShareDialog } = useJournalsApi()
  const userInfo = useUserInfo()
  const journals = useJournals()
  const journalsShared = useJournalsSharedWithMe()
  const journalsAll = useJournalsAll()

  const aliasesAll = useApi<AliasesGetResponse>('/api/dashboard/aliases')

  const navigate = useNavigate()

  const applyJournal = (journal: JournalInfo | null) => {
    if (journal === null) {
      setFilters((filters) => ({ ...filters, journal: null }))
      return
    }
    if (journal.id === filters.journal) return
    setFilters((filters) => ({ ...filters, journal: journal.id }))
  }

  const { isScrolled } = useScrolling()
  const { dateFormatter } = useGlobalTimezone()

  const [selectedJournal, setSelectedJournal] = useState<JournalInfo | null>(null)
  useEffect(() => {
    if (selectedJournal === null && filters.journal === '') return
    if (selectedJournal?.id === filters.journal) return
    setSelectedJournal(journalsAll.data?.find((journal) => journal.id === filters.journal) ?? null)
  }, [filters.journal, journalsAll.data, selectedJournal])

  const {
    selectedInstrumentsTooltipContent,
    selectedJournalTooltipContent,
    shareButtonTooltipContent,
  } = useTooltipText(selectedJournal)

  const journalsGetVisualValue = useCallback(
    (journal: JournalInfo | null): ReactNode => {
      if (!journal) return 'All my journals'
      if (journal.owner.userEmail === userInfo.data?.userEmail) return journal.journalName
      return (
        <div className="journal-filter-centered">
          <img src={IconsSrc.Share} alt="Text" /> {journal.journalName}
        </div>
      )
    },
    [userInfo.data?.userEmail]
  )

  return (
    <div className={classNames('Filters', isScrolled && 'scrolled', className || '')} {...props}>
      <div className="filters">
        <div className="filters__content">
          <SimpleDropdown<JournalInfo | null>
            className="filter"
            value={selectedJournal}
            onChange={applyJournal}
            tooltipContent={selectedJournalTooltipContent}
            getVisualValue={journalsGetVisualValue}
          >
            <SimpleDropdown.Item value={null}>
              <SimpleDropdown.ItemText
                primary="All my journals"
                secondary={
                  (journals.data?.length ?? 1) +
                  ` journal${journals.data?.length === 1 ? '' : 's'} owned`
                }
              />
            </SimpleDropdown.Item>
            {journalsShared.data?.length !== 0 && (
              <div className="filters-mid-item-divider">My journals</div>
            )}
            {journals.data
              ? journals.data.map((journal) => (
                  <SimpleDropdown.Item value={journal} key={journal.id}>
                    <SimpleDropdown.ItemText
                      primary={journal.journalName}
                      secondary={
                        journal.updateDate
                          ? 'Updated on ' + dateFormatter.format(new Date(journal.updateDate))
                          : '-'
                      }
                    />
                  </SimpleDropdown.Item>
                ))
              : null}
            {journalsShared.data?.length !== 0 && (
              <div className="filters-mid-item-divider">Shared with me</div>
            )}
            {journalsShared.data
              ? journalsShared.data.map((journal) => (
                  <SimpleDropdown.Item value={journal} key={journal.id}>
                    <SimpleDropdown.ItemText
                      primary={journal.journalName}
                      secondary={
                        journal.updateDate
                          ? 'Updated on ' + dateFormatter.format(new Date(journal.updateDate))
                          : '-'
                      }
                    />
                  </SimpleDropdown.Item>
                ))
              : null}
            <SimpleDropdown.FooterSection>
              <SimpleDropdown.Divider />
              <SimpleDropdown.ActionItem onClick={() => openAddDialog()}>
                <img src={imgPlus} alt="Add" /> Add journal
              </SimpleDropdown.ActionItem>
            </SimpleDropdown.FooterSection>
          </SimpleDropdown>
          <MultiselectDropdown
            className="filter"
            value={filters.symbols}
            allValues={aliasesAll.data?.result ?? []}
            placeholder="All Instruments"
            tooltipContent={selectedInstrumentsTooltipContent}
            onChange={(sy) => setFilters((v) => ({ ...v, symbols: sy }))}
          ></MultiselectDropdown>

          <div className="label">from</div>
          <DateInput
            className="filter"
            value={filters.dateFrom}
            onChange={(date) => setFilters((filters) => ({ ...filters, dateFrom: date }))}
          />
          <div className="label">to</div>
          <DateInput
            className="filter"
            value={filters.dateTo}
            onChange={(date) => setFilters((filters) => ({ ...filters, dateTo: date }))}
          />
        </div>
      </div>

      <div className="control-buttons">
        <Button appearance="solid" className="panel-button" onClick={() => openAddDialog()}>
          <img src={imgPlus} alt="Create" /> <span className="medium">Add</span>{' '}
          <span className="big">new journal</span>
        </Button>
        <Button
          appearance="outline"
          className="panel-button"
          tooltipContent="Manage your journals"
          onClick={() => navigate('/manage-journals')}
        >
          <img src={imgSettings} alt="Manage" />
          <span className="medium">Manage</span>
        </Button>

        <Button
          appearance="outline"
          color="primary"
          disabled={
            !selectedJournal || selectedJournal?.owner.userEmail !== userInfo.data?.userEmail
          }
          onClick={() => selectedJournal && openShareDialog(selectedJournal.id)}
          tooltipContent={shareButtonTooltipContent}
        >
          <img src={IconsSrc.Share} alt="Manage" className="icon" />
          <span className="medium">Share</span>
        </Button>
      </div>
    </div>
  )
}
