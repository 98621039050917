import { FilterContext } from 'core/contexts/FilterContext'
import { useJournalsAll } from 'core/hooks/useJournalsAll'
import { JournalInfo } from 'core/types'
import { useContext, useEffect, useState } from 'react'

export function useSelectedJournal() {
  const [selectedJournal, setSelectedJournal] = useState<JournalInfo | null>(null)
  const { filters } = useContext(FilterContext)
  const journalsAll = useJournalsAll()

  useEffect(() => {
    if (selectedJournal === null && filters.journal === '') return
    if (selectedJournal?.id === filters.journal) return
    setSelectedJournal(journalsAll.data?.find((journal) => journal.id === filters.journal) ?? null)
  }, [filters.journal, journalsAll.data, selectedJournal])

  return selectedJournal
}
