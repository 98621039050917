import './TradeInfoNoteSection.scss'

import { AllHTMLAttributes, useContext } from 'react'
import { Button } from 'shared/Button'
import { TradeInfoModalContext } from '../../TradeInfoModalContext'
import { TradeInfoNoteForm } from './TradeInfoNoteForm'
import { TradeInfoNotePreview } from './TradeInfoNotePreview'
import Menu from 'shared/Menu'
import Icon from 'shared/Icon'

type TradeInfoDialogNoteFormProps = AllHTMLAttributes<HTMLDivElement>
export function TradeInfoNoteSection(props: Readonly<TradeInfoDialogNoteFormProps>) {
  const { editMode, setEditMode, onClose, tradeItem } = useContext(TradeInfoModalContext)

  return (
    <div className="TradeInfoNoteSection" {...props}>
      <div className="heading">
        <div className="text text-normal-bold">Note</div>
        {tradeItem.tradeNote && !editMode && (
          <Menu
            trigger={
              <Button className="menu-button" square appearance="menu-item">
                <Icon name="DotsVertical" />
              </Button>
            }
          >
            <Menu.Item onClick={() => setEditMode(true)}>Edit</Menu.Item>
            <Menu.Item>Remove</Menu.Item>
          </Menu>
        )}
      </div>
      {editMode ? (
        <TradeInfoNoteForm />
      ) : tradeItem.tradeNote ? (
        <TradeInfoNotePreview />
      ) : (
        <TradeEmptyState />
      )}
      {editMode || (
        <div className="buttons">
          <Button onClick={() => onClose()}>OK</Button>
        </div>
      )}
    </div>
  )
}

function TradeEmptyState() {
  return (
    <div className="TradeEmptyState">
      <div className="heading">
        <div className="text">Note</div>
      </div>
      <div className="content"></div>
    </div>
  )
}
