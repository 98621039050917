import {
  addMediaToTrade,
  addNote,
  editNote,
  useMediaForTrade,
  deleteMediaForTrade,
} from 'core/api/trades'
import { AllHTMLAttributes, useContext, useMemo, useRef, useState } from 'react'
import { TradeInfoModalContext } from '../../TradeInfoModalContext'
import classNames from 'classnames'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'
import { DashboardApiContext } from 'pages/DashboardPage/DashboardApiContext'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { MediaItem } from 'core/types'

type TradeInfoDialogNoteFormProps = AllHTMLAttributes<HTMLDivElement>
export function TradeInfoNoteForm(props: Readonly<TradeInfoDialogNoteFormProps>) {
  const { onClose, tradeItem } = useContext(TradeInfoModalContext)
  const { value, setValue, isLoading, submitNote } = useNoteValue(onClose)
  const mediaResponse = useMediaForTrade(tradeItem.id)

  console.log(tradeItem)

  return (
    <div className="TradeInfoNoteForm" {...props}>
      <div className="form-control">
        {/* <div className="label text-normal-bold">Note</div> */}
        <textarea
          className={classNames('input', mediaResponse.data?.result.length ? 'imaginable' : '')}
          placeholder="Type your note here..."
          rows={3}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>

      <div className="media-row">
        {mediaResponse.data?.result.map((media) => (
          <EditableImage media={media} tradeId={tradeItem.id} onUpdate={mediaResponse.mutate} />
        ))}
        <FileChooser />
      </div>

      <div className="buttons">
        <Button appearance="outline">Clear note</Button>
        <Button color="primary" isLoading={isLoading} onClick={() => submitNote()}>
          Save
        </Button>
      </div>
    </div>
  )
}

type EditableImageProps = { media: MediaItem; tradeId: string; onUpdate: () => Promise<unknown> }
function EditableImage({ media, onUpdate, tradeId }: Readonly<EditableImageProps>) {
  const { callAndReport } = useCallAndReport()
  const deleteMedia = async (mediaKey: string) => {
    await callAndReport(
      async () => {
        await deleteMediaForTrade(tradeId, mediaKey)
        await onUpdate()
      },
      { DEFAULT_ERR: 'Failed to delete image', OK: 'Image removed' }
    )
  }

  return (
    <div className="EditableImage" key={media.key}>
      <Button
        square
        size="small"
        tooltipContent={'Remove image'}
        onClick={() => deleteMedia(media.key)}
      >
        <Icon name="Trash" width="16px" height="16px" />
      </Button>
      <img className="attached-media" src={media.thumbnailSignedUrl} alt="Attached" />
    </div>
  )
}

function useNoteValue(onClose: () => void) {
  const { tradeItem } = useContext(TradeInfoModalContext)
  const [value, setValue] = useState(tradeItem.tradeNote?.noteText ?? '')
  const { mutateAll } = useContext(DashboardApiContext)

  const [initialValue] = useState(tradeItem.tradeNote)
  const noteWasChanged = useMemo(() => {
    if (value === '' && initialValue === null) return false
    return value !== initialValue?.noteText
  }, [value, initialValue])

  const { callAndReport, isLoading } = useCallAndReport()
  const submitNote = async () => {
    if (!noteWasChanged) return onClose()
    await callAndReport(
      async () => {
        if (initialValue === null) {
          await addNote(value, tradeItem.id)
        } else {
          await editNote(value, initialValue.id)
        }
        await mutateAll()
        onClose()
      },
      {
        OK: 'Note updated',
        DEFAULT_ERR: 'Failed to update note',
      }
    )
  }

  return { value, setValue, isLoading, submitNote }
}

const TRADE_NOTE_ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/webp']
type FileChooserProps = { maxSize?: number }
function FileChooser({ maxSize = 5 * 1024 * 1024 }: FileChooserProps) {
  const { callAndReport } = useCallAndReport()
  const { tradeItem } = useContext(TradeInfoModalContext)
  const media = useMediaForTrade(tradeItem.id)
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)

  const uploadImage = async (file: File) =>
    callAndReport(
      async () => {
        setUploadProgress(0)
        const response = (
          await addMediaToTrade(tradeItem.id, file, (ev) => setUploadProgress(ev.progress ?? 0))
        ).data
        setUploadProgress(null)
        await media.mutate()
        return response
      },
      { DEFAULT_ERR: 'Failed to upload image' }
    )

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (!selectedFile) return
    if (selectedFile.size > maxSize) {
      return alert('File size should be less than 5MB')
    }
    if (!TRADE_NOTE_ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      return alert('File should be an image')
    }
    await uploadImage(selectedFile)
    resetFileInput()
  }

  const fileInputRef = useRef<HTMLInputElement>(null)
  const openFileChooser = () => {
    fileInputRef.current?.click()
  }

  return (
    <div className="FileChooser">
      <input ref={fileInputRef} type="file" onChange={handleChange} />
      <Button onClick={openFileChooser} isLoading={uploadProgress !== null}>
        <Icon name="Plus" width="24px" height="24px" />
      </Button>
      <div className="progress-overlay" style={{ height: (uploadProgress ?? 0) * 100 + '%' }}></div>
    </div>
  )
}
