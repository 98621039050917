import { ChartValueType } from '../models'
import { durationToHumanReadable } from 'core/utils'
import { ReactNode, useMemo } from 'react'
import TextMoney from 'shared/TextMoney'

type CommonTooltipProps = {
  payload: any
  valueType: ChartValueType
} & any
export function CommonTooltip({ payload, valueType }: CommonTooltipProps) {
  const content: { classifier: string; value: number } | undefined = payload[0]?.payload
  if (!content) return null

  return (
    <div className="CommonTooltip">
      <div className="classifier">{content.classifier}</div>
      <TooltipValue value={content.value} valueType={valueType} />
    </div>
  )
}

type TooltipValueProps = { value: number; valueType: ChartValueType }
function TooltipValue({ value, valueType }: Readonly<TooltipValueProps>) {
  const formattedValue: ReactNode = useMemo(() => {
    if (valueType === 'MONEY') return <TextMoney value={value} colored={true} />
    if (valueType === 'TRADES') return value.toString() + ' trades'
    if (valueType === 'DURATION_MS') return durationToHumanReadable(value)
    return value.toString()
  }, [value, valueType])

  return <div className="value">{formattedValue}</div>
}
