import './DashboardPage.scss'
import { DashboardApiContextProvider } from './DashboardApiContext'
import PageLayout from 'shared/PageLayout/PageLayout'
import Dashboard from './Dashboard'
import { useDashboardPageSearchParamsResolver } from './useDashboardPageSearchParamsResolver'
import Tabs from 'shared/Tabs'
import Filters from './Dashboard/sections/Filters'
import { TradesTableSection } from './Dashboard/sections/TradesTableSection/TradesTableSection'
import Icon from 'shared/Icon'

export function DashboardPage() {
  return (
    <DashboardApiContextProvider>
      <DashboardPageWithContexts />
    </DashboardApiContextProvider>
  )
}

function DashboardPageWithContexts() {
  useDashboardPageSearchParamsResolver()

  return (
    <PageLayout className="HomePage">
      <PageLayout.Header />
      <PageLayout.Content>
        <Tabs defaultTabId="dashboard">
          <Tabs.Buttons className="tabs-buttons-container">
            <Tabs.Button tabId="dashboard">
              <Icon name="Dashboard" />
              Dashboard
            </Tabs.Button>
            <Tabs.Button tabId="trade-history">
              <Icon name="Table" />
              Trades history
            </Tabs.Button>
          </Tabs.Buttons>
          <Filters style={{ marginBottom: '8px' }} />

          <Tabs.Content tabId="dashboard">
            <Dashboard />
          </Tabs.Content>
          <Tabs.Content tabId="trade-history">
            <div className="TradesHistory">
              <TradesTableSection />
            </div>
          </Tabs.Content>
        </Tabs>
      </PageLayout.Content>
      <PageLayout.Footer />
    </PageLayout>
  )
}
