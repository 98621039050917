import { API_URL } from 'core/constants'
import apiClient from './apiClient'
import useSWR from 'swr'
import { apiClientFetcher } from './apiClientFetcher'
import { MediaItem } from 'core/types'
import { AxiosProgressEvent } from 'axios'

// CRUD operations for trade notes
export const addNote = async (noteText: string, tradeId: string) => {
  return apiClient.post(API_URL + `/api/dashboard/trades/${tradeId}/note`, { noteText })
}

export const editNote = async (noteText: string, tradeNoteId: string) => {
  return apiClient.put(API_URL + `/api/dashboard/trades/note/${tradeNoteId}`, { noteText })
}

// CRUD operations for trade notes media
export const addMediaToTrade = async (
  tradeId: string,
  media: File,
  onUploadProgress?: (event: AxiosProgressEvent) => void
) => {
  const formData = new FormData()
  formData.append('file', media)
  return apiClient.post(API_URL + `/api/dashboard/trades/${tradeId}/media`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  })
}

export const getMediaForTrade = async (tradeId: string) => {
  return apiClient.get(API_URL + `/api/dashboard/trades/${tradeId}/media`)
}
export const useMediaForTrade = (tradeId: string) => {
  const url = API_URL + `/api/dashboard/trades/${tradeId}/media`
  return useSWR(url, apiClientFetcher<{ result: MediaItem[] }>)
}

export const deleteMediaForTrade = async (tradeId: string, mediaKey: string) => {
  console.log('media key', encodeURI(mediaKey))
  return apiClient.delete(
    API_URL + `/api/dashboard/trades/${tradeId}/media?key=${encodeURI(mediaKey)}`
  )
}
