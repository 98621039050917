import { useMemo, useState } from 'react'
import { FilterContextData, FilterState } from './models'
import { FilterContext } from './FilterContext'
import { getInitialFilters } from './utils'
import { useFilterStateLocalStorageSync } from './hooks'

export function FilterContextProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [filters, setFilters] = useState<FilterState>(getInitialFilters())
  useFilterStateLocalStorageSync(filters)

  const providerValue = useMemo(() => {
    return {
      filters,
      setFilters,
    } satisfies FilterContextData
  }, [filters, setFilters])

  return <FilterContext.Provider value={providerValue}>{children}</FilterContext.Provider>
}
