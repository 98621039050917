import App from 'App'
import { DashboardPage } from 'pages/DashboardPage/DashboardPage'
import { Login } from 'pages/Login/Login'
import { ManageJournalsPage } from 'pages/ManageJournalsPage/ManageJournalsPage'
import MetricsPage from 'pages/MetricsPage'
import { SignUp } from 'pages/SignUp/SignUp'
import { Navigate, createBrowserRouter } from 'react-router-dom'

export const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: '/dashboard',
        element: <DashboardPage />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/signup',
        element: <SignUp />,
      },
      {
        path: 'manage-journals',
        element: <ManageJournalsPage />,
      },
      {
        path: 'metrics',
        element: <MetricsPage />,
      },
    ],
  },
])
